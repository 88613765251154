<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ label }}</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-sister-company"
                      >Sister Company
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ label_path }}
                  </li>
                </ol>
              </nav>
            </div>
            <div
              class="button-edit"
              v-show="validated == 1 && label_path == 'Edit'"
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div
              v-show="
                (validated != 1 && label_path == 'Edit') ||
                label_path == 'Tambah'
              "
            >
              <button
                class="btn btn-save"
                type="submit"
                :disabled="
                  isSubmit || (msgCheckKode != '' && msgCheckKode != null)
                "
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>
          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">Detail Sister Company</div>
                <div class="sub-title">Tambah informasi yang diperlukan</div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div
                class="col-12 col-sm-12 col-md-3 d-flex justify-content-center"
              >
                <div class="profile-company mb-4">
                  <div class="profile-image">
                    <img :src="imageFile.img" :alt="imageFile.img" />
                  </div>
                  <input
                    type="file"
                    style="display: none"
                    id="file"
                    @change="changeFile"
                    accept="image/*"
                  />
                  <label
                    for="file"
                    class="profile-update"
                    v-if="validated != 1 || label_path != 'Edit'"
                    >Upload Logo</label
                  >
                  <span v-if="validated != 1 || label_path != 'Edit'"
                    >*) ukuran file tidak lebih dari 2mb dan hanya menerima
                    ekstensi png, jpg, jpeg</span
                  >
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-9">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="nama">Kode Perusahaan : </label>
                      <input
                        :disabled="validated == 1 && label_path == 'Edit'"
                        type="text"
                        class="form-control text-uppercase"
                        id="kode"
                        @change="checkKode"
                        @keyup="formChange('kode')"
                        v-model="formData.kode"
                        v-maska="'XXXXXX'"
                        :class="{ 'is-invalid': formError && formError.kode }"
                        placeholder="Masukkan Kode Perushaan"
                      />
                      <div
                        class="form-error"
                        v-if="
                          formError &&
                          formError.kode &&
                          (!msgCheckKode ||
                            msgCheckKode == '' ||
                            msgCheckKode == null)
                        "
                      >
                        {{ formError.kode }}
                      </div>
                      <div
                        class="form-error"
                        v-if="
                          msgCheckKode &&
                          msgCheckKode != '' &&
                          msgCheckKode != null &&
                          formError.length == 0
                        "
                      >
                        {{ msgCheckKode }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="nama">Nama Perusahaan : </label>
                      <input
                        :disabled="validated == 1 && label_path == 'Edit'"
                        type="text"
                        class="form-control"
                        id="nama"
                        @keyup="formChange('nama')"
                        v-model="formData.nama"
                        :class="{ 'is-invalid': formError && formError.nama }"
                        placeholder="Masukkan Nama Persuhaan"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.nama"
                      >
                        {{ formError.nama }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="no_telp">Nomor Telepon:</label>
                      <input
                        :disabled="validated == 1 && label_path == 'Edit'"
                        type="text"
                        class="form-control"
                        id="no_telp"
                        v-maska="'################'"
                        @keyup="formChange('no_telp')"
                        v-model="formData.no_telp"
                        :class="{
                          'is-invalid': formError && formError.no_telp,
                        }"
                        placeholder="Masukkan Nomor Telepon"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.no_telp"
                      >
                        {{ formError.no_telp }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="email">Email :</label>
                      <input
                        :disabled="validated == 1 && label_path == 'Edit'"
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Masukkan Email"
                        @keyup="formChange('email')"
                        v-model="formData.email"
                        :class="{ 'is-invalid': formError && formError.email }"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.email"
                      >
                        {{ formError.email }}
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="npwp">NPWP :</label>
                      <input
                        :disabled="validated == 1 && label_path == 'Edit'"
                        type="text"
                        class="form-control npwp"
                        v-maska="'##.###.###.#-###.###'"
                        id="npwp"
                        placeholder="Masukkan NPWP"
                        @keyup="formChange('npwp')"
                        v-model="formData.npwp"
                        :class="{ 'is-invalid': formError && formError.npwp }"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.npwp"
                      >
                        {{ formError.npwp }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Periode Akuntansi :</label>
                      <Select2
                        :disabled="validated == 1 && label_path == 'Edit'"
                        :options="optionPeriode"
                        placeholder="Periode Akuntansi"
                        v-model="formData.periode_akuntansi"
                        :class="{
                          'is-invalid':
                            formError && formError.periode_akuntansi,
                        }"
                        @change="myChangeEvent($event, 'periode_akuntansi')"
                        @select="myChangeEvent($event, 'periode_akuntansi')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.periode_akuntansi"
                      >
                        {{ formError.periode_akuntansi }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Status :</label>
                      <Select2
                        :disabled="validated == 1 && label_path == 'Edit'"
                        :options="statusCompany"
                        placeholder="Periode Akuntansi"
                        v-model="formData.status"
                        :class="{ 'is-invalid': formError && formError.status }"
                        :settings="{ minimumResultsForSearch: -1 }"
                        @change="myChangeEvent($event, 'status')"
                        @select="myChangeEvent($event, 'status')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.status"
                      >
                        {{ formError.status }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="title">Alamat Sister Company</div>
                <div class="sub-title">
                  Tambah alamat sister company yang diperlukan
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="inputAddress2">Provinsi :</label>
                  <Select2
                    :disabled="validated == 1 && label_path == 'Edit'"
                    v-model="formData.id_provinsi"
                    :options="OptionsProvince"
                    placeholder="Provinsi"
                    :class="{
                      'is-invalid': formError && formError.id_provinsi,
                    }"
                    @change="locationEvent($event, 'id_provinsi')"
                    @select="locationEvent($event, 'id_provinsi')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_provinsi"
                  >
                    {{ formError.id_provinsi }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputAddress2">Kabupaten :</label>
                  <Select2
                    :disabled="validated == 1 && label_path == 'Edit'"
                    v-model="formData.id_kabupaten"
                    :options="OptionsRegion"
                    placeholder="Kabupaten"
                    :settings="{ settingOption: value, settingOption: value }"
                    :class="{
                      'is-invalid': formError && formError.id_kabupaten,
                    }"
                    @change="locationEvent($event, 'id_kabupaten')"
                    @select="locationEvent($event, 'id_kabupaten')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_kabupaten"
                  >
                    {{ formError.id_kabupaten }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputAddress2">Kecamatan :</label>
                  <Select2
                    :disabled="validated == 1 && label_path == 'Edit'"
                    v-model="formData.id_kecamatan"
                    :options="OptionsDistrict"
                    placeholder="Kecamatan"
                    :settings="{ settingOption: value, settingOption: value }"
                    :class="{
                      'is-invalid': formError && formError.id_kecamatan,
                    }"
                    @change="locationEvent($event, 'id_kecamatan')"
                    @select="locationEvent($event, 'id_kecamatan')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_kecamatan"
                  >
                    {{ formError.id_kecamatan }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="lokasi">Lokasi :</label>
                  <textarea
                    :disabled="validated == 1 && label_path == 'Edit'"
                    type="text"
                    class="form-control"
                    id="lokasi"
                    @keyup="formChange('alamat')"
                    v-model="formData.alamat"
                    :class="{ 'is-invalid': formError && formError.alamat }"
                    placeholder="Masukkan Lokasi"
                  ></textarea>
                  <div class="form-error" v-if="formError && formError.alamat">
                    {{ formError.alamat }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="title">Rekening Perusahaan</div>
                <div class="sub-title">
                  Tambah data rekening perusahaan yang diperlukan
                </div>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-12">
                <div class="form-error mb-2" v-if="errorRekening">
                  Masukkan rekening perusahaan dengan benar
                </div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Atas Nama</th>
                      <th style="width: 20%">Bank</th>
                      <th>Nomor Rekening</th>
                      <th style="width: 80px" v-if="validated != 1">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, index) in listRekening" :key="index">
                      <td>
                        <input
                          :disabled="validated == 1"
                          type="text"
                          class="form-control"
                          :id="`rekening_nama${index}`"
                          @keyup="formChangeRekening(index)"
                          v-model="listRekening[index].rekening_nama"
                          :class="{
                            'is-invalid':
                              errorRekening &&
                              !listRekening[index].rekening_nama,
                          }"
                          placeholder="Masukkan Nomor Rekening"
                        />
                      </td>
                      <td>
                        <Select2
                          :disabled="validated == 1"
                          class="editable"
                          v-model="listRekening[index].rekening_bank"
                          :options="optionBank"
                          placeholder="Bank"
                          :id="`pilihanBank${index}`"
                          :class="{
                            'is-invalid':
                              errorRekening &&
                              !listRekening[index].rekening_bank,
                          }"
                          @change="formChangeRekening(index, $event)"
                          @select="formChangeRekening(index, $event)"
                        />
                      </td>
                      <td>
                        <input
                          :disabled="validated == 1"
                          type="text"
                          class="form-control"
                          v-maska="
                            '#############################################'
                          "
                          :id="`rekening_nomor${index}`"
                          :class="{
                            'is-invalid':
                              errorRekening &&
                              !listRekening[index].rekening_nomor,
                          }"
                          @keyup="formChangeRekening(index)"
                          v-model="listRekening[index].rekening_nomor"
                          placeholder="Masukkan Nomor Rekening"
                        />
                      </td>
                      <td v-if="validated != 1">
                        <div class="d-flex justify-content-center">
                          <img
                            src="/img/avatar/icon-plus.svg"
                            @click="addRekening()"
                            class="btn-add-row"
                            style="cursor: pointer"
                          />
                          <img
                            src="/img/avatar/icon-delete.svg"
                            @click="deleteRekening(index)"
                            v-if="index != 0"
                            class="btn-add-row ml-1"
                            style="cursor: pointer; width: 20px"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
// import FooterDashboard from '../src/components/Footer.vue'
import Select2 from "vue3-select2-component";
import SidebarDashboard from "../../../../src/components/Sidebar.vue";
import NavbarDashboard from "../../../../src/components/Navbar.vue";
import { loadScript } from "vue-plugin-load-script";
import { checkRules, cksClient, showAlert } from "../../../helper";
import {
  get_CompanyDetail,
  post_CompanySave,
  get_CompanyCheckCode,
} from "../../../actions/company";
import {
  get_ListBank,
  get_ListKabupaten,
  get_ListKecamatan,
  get_ListProvinsi,
} from "../../../actions/master";
loadScript("/assets/js/stisla.js");
loadScript("/assets/js/scripts.js");
loadScript("/assets/js/custom.js");
loadScript("/assets/js/page/index.js");
import { maska } from "maska";
import { DATA } from "../../../constans/Url";

export default {
  name: "DashboardLayout",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
  },
  directives: {
    maska,
  },
  data() {
    return {
      validated: 1,
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ], // or [{id: key, text: value}, {id: key, text: value}]
      optionPeriode: [
        { id: "1", text: "Januari" },
        { id: "2", text: "Februari" },
        { id: "3", text: "Maret" },
        { id: "4", text: "April" },
        { id: "5", text: "Mei" },
        { id: "6", text: "Juni" },
        { id: "7", text: "Juli" },
        { id: "8", text: "Agustus" },
        { id: "9", text: "September" },
        { id: "10", text: "Oktober" },
        { id: "11", text: "November" },
        { id: "12", text: "Desember" },
      ],
      OptionsRegion: [],
      OptionsDistrict: [],
      OptionsProvince: [],
      optionBank: [],
      id_company: this.$route.params.id ? this.$route.params.id : "",
      label: this.$route.params.id
        ? "Edit Sister Company"
        : "Tambah Sister Company",
      label_path: this.$route.params.id ? "Edit" : "Tambah",
      formData: {
        id: this.$route.params.id ? this.$route.params.id : "",
        id_parent: cksClient().get("_account").id_company,
        kode: "",
        nama: "",
        alamat: "",
        email: "",
        no_telp: "",
        periode_akuntansi: "",
        id_kecamatan: "",
        id_kabupaten: "",
        id_provinsi: "",
        npwp: "",
        status: "aktif",
        logo: "",
        rekening: [],
      },
      imageFile: {
        default: this.getImage("company.png"),
        img: this.getImage("company.png"),
        file: "",
      },
      msgCheckKode: "",
      rules: {
        kode: {
          required: true,
        },
        nama: {
          required: true,
        },
        alamat: {
          required: true,
        },
        id_kecamatan: {
          required: true,
        },
        id_kabupaten: {
          required: true,
        },
        id_provinsi: {
          required: true,
        },
        periode_akuntansi: {
          required: true,
        },
        status: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        npwp: {
          required: true,
        },
        no_telp: {
          required: true,
        },
      },
      formError: [],
      listRekening: [
        {
          id: "",
          rekening_nama: "",
          rekening_bank: "",
          rekening_nomor: "",
        },
      ],
      errorRekening: false,
      isSubmit: false,
    };
  },
  created() {
    this.getBank();
    if (this.id_company) {
      this.getCompany();
    }
    this.getProvinsi();
  },
  // mounted() {

  // },
  methods: {
    getBank() {
      get_ListBank((res) => {
        this.optionBank = res.list;
      });
    },
    checkKode() {
      if (this.formData.kode) {
        this.msgCheckKode = "Tunggu sebentar....";
        get_CompanyCheckCode(
          {
            kode: this.formData.kode.toLowerCase().toUpperCase(),
            id_company: this.formData.id,
          },
          (res) => {
            if (res.is_exist) {
              this.msgCheckKode = "Kode sudah tersedia";
            } else {
              this.msgCheckKode = "";
            }
          },
          () => {
            this.msgCheckKode = "Terjadi kesalahan, silakan ulangi kembali";
          }
        );
      } else {
        this.msgCheckKode = "Inputan ini harus anda isi";
      }
    },
    editable() {
      this.validated = 2;
    },
    clickCancel() {
      this.$router.push({ name: "dataSisterCompany" });
    },
    getImage(image) {
      return DATA.COMPANY(image);
    },

    changeFile(ev) {
      var exAvailable = ["png", "jpg", "jpeg", "PNG", "JPG", "JPEG"];
      var file = ev.target.files[0];
      if (file) {
        var name = file.name.split(".");
        var size = file.size;
        if (
          exAvailable.indexOf(name[name.length - 1]) >= 0 &&
          Math.round(size / 1024 ** 2) <= 2
        ) {
          const reader = new FileReader();

          reader.onload = (e) => {
            this.imageFile.img = e.target.result;
            this.formData.logo = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          this.imageFile.img = this.imageFile.default;
          this.formData.logo = "";
        }
      } else {
        this.imageFile.img = this.imageFile.default;
      }
    },

    async getCompany() {
      await get_CompanyDetail(this.id_company, (res) => {
        this.formData.id = res.id;
        this.formData.nama = res.nama;
        this.formData.kode = res.kode;
        this.formData.status = res.status;
        this.formData.id_parent = res.id_parent;
        this.formData.periode_akuntansi = res.periode_akuntansi;
        this.formData.email = res.email;
        this.formData.no_telp = res.no_telp;
        this.formData.npwp = res.npwp;
        this.formData.id_provinsi = res.id_provinsi;
        this.getKabupaten(res.id_provinsi);
        this.formData.id_kabupaten = res.id_kabupaten;
        this.getKecamatan(res.id_kabupaten);
        this.formData.id_kecamatan = res.id_kecamatan;
        this.formData.alamat = res.alamat;
        this.imageFile.default = this.getImage(res.logo);
        this.imageFile.img = this.getImage(res.logo);

        if (res.list_rekening.length > 0) {
          this.listRekening = [];
        }
        for (const key in res.list_rekening) {
          if (Object.hasOwnProperty.call(res.list_rekening, key)) {
            const element = res.list_rekening[key];
            this.listRekening.push({
              id: element.id,
              rekening_bank: element.bank,
              rekening_nama: element.atas_nama,
              rekening_nomor: element.nomor_rekening,
            });
          }
        }
      });
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    formChangeRekening(index) {
      this.errorRekening =
        !this.listRekening[index].rekening_bank ||
        !this.listRekening[index].rekening_nama ||
        !this.listRekening[index].rekening_nomor;
    },
    addRekening() {
      this.listRekening.push({
        id: "",
        rekening_nama: "",
        rekening_bank: "",
        rekening_nomor: "",
      });
    },
    deleteRekening(index) {
      this.listRekening.splice(index, 1);
    },
    async getProvinsi() {
      await get_ListProvinsi(
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsProvince = arr;
        },
        () => {
          this.OptionsProvince = [];
        }
      );
    },
    async getKabupaten(id) {
      await get_ListKabupaten(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsRegion = arr;
        },
        () => {
          this.OptionsRegion = [];
        }
      );
    },

    async getKecamatan(id) {
      await get_ListKecamatan(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsDistrict = arr;
        },
        () => {
          this.OptionsDistrict = [];
        }
      );
    },
    locationEvent(val, key) {
      this.formChange(key);
      if (key === "id_provinsi") {
        this.OptionsDistrict = [];
        this.OptionsRegion = [];
        this.getKabupaten(val.id);
      } else if (key === "id_kabupaten") {
        this.OptionsDistrict = [];
        this.getKecamatan(val.id);
      }
    },
    myChangeEvent(val, key) {
      console.log(val);
      this.formChange(key);
    },
    postData() {
      this.isSubmit = true;
      this.formData.kode = this.formData.kode.toLowerCase().toUpperCase();
      this.formData.rekening = this.listRekening;
      post_CompanySave(
        this.formData,
        (res) => {
          this.formData.logo = "";
          this.isSubmit = false;
          var msg = "";
          if (res.response_code === 201) {
            msg = "Data Sister Company berhasil ditambahkan";
          } else {
            msg = "Data Sister Company berhasil diperbarui";
          }
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            showCancelButton: false,
            showConfirmButton: true,
            onSubmit: () => {
              this.$router.push({ name: "dataSisterCompany" });
            },
          });
        },
        () => {
          this.formData.logo = "";
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      this.formChangeRekening(0);
      for (const key in this.rules) {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success && !this.errorRekening) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.profile-company .profile-image {
  width: 200px;
  height: 200px;
  border: 1px solid #eceff1;
  border-radius: 3px;
  padding: 1rem;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.profile-company .profile-image img {
  width: 100%;
  height: auto;
}

.profile-company .profile-update {
  border: 1px solid #eceff1;
  border-radius: 3px;
  margin-top: 1rem;
  width: 200px;
  padding: 10px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  /* transition: all 1s ease-in; */
}
.profile-company .profile-update:hover {
  background-color: #68caf8;
  color: white;
  /* transition: all 0.1s ease-in; */
}
.profile-company span {
  display: block;
  width: 200px;
  font-size: 12px;
}
</style>
